import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Head from 'next/head';
import getEntityField from '~/src/lib/getEntityField';
import { withRouter } from 'next/router';
import handleAuth from '~/src/lib/handleAuth';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

const Home = dynamic(() => import('~/src/containers/Home'), {
    ssr: false,
    loading: () => <p>...</p>,
});

function App() {
    const app = useSelector((state) => state.app);
    const business = useSelector((state) => state.business);

    const [ready, setReady] = useState(false);
    const router = useRouter();
    //router.replace('/en');

    useEffect(() => {
        handleAuth();
        setReady(true);
        window.scrollTo(0, 0);
        document.querySelector('body').setAttribute('id', 'home');
    }, []);

    //console.log(11111);
    //console.log(app);

    if (app && app.errorDomain === true) {
        router.push('/404');
        return false;
    }

    //console.log(22222);

    if (!business) {
        return false;
    }

    //console.log(33333);

    if (Object.keys(business).length === 0) {
        return false;
    }

    //console.log(444444);

    if (ready === false) {
        return false;
    }

    //console.log(55555);

    return (
        <>
            <Head>
                <title>{business.info.name}</title>
                <meta
                    name="description"
                    content={getEntityField(business.info, 'information')}
                />
            </Head>
            <Home />
        </>
    );
}

export default App;
